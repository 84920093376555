<template>
	<div>
		<a-header />
		<z-stepper />
		<z-sidebar-help style="z-index: 4000;" v-if="isRouteForHelp" />
		<div class="content">
			<div class="pt-5">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		'z-sidebar-help': () => import('@/plugins/app/order/components/z-sidebar-help.vue'),
		'a-header': () => import('@/plugins/app/_layout/a-header.vue'),
		'z-stepper': () => import('@/plugins/app@components/stepper/z-stepper.vue')
	},
	computed: {
		isRouteForHelp() {
			const validRoutes = [ 'Order', 'Registration', 'Login', 'StudentsInformation', 'Summary', 'Acknowledgment']
			return validRoutes.includes(this.$route.name)
		},
		// Nadstavba ak sa chce menit link podla toho na akej route sme
		// getHelpLink() {
		// 	let link;
		// 	switch (this.$route.name) {
		// 		case 'Order':
		// 			link = 'order'
		// 			break;
		// 		case 'Registration':
		// 			link = 'registration'
		// 			break;
		// 		case 'Login':
		// 			link = 'login'
		// 			break;
		// 		case 'StudentsInformation':
		// 			link = 'studentsInformation'
		// 			break;
		// 		case 'Summary':
		// 			link = 'summary'
		// 			break;
		// 		case 'Acknowledgment':
		// 			link = 'acknowledgment'
		// 			break;
		// 	}
		// 	return link
		// }
	}
}
</script>

<style lang="sass" scoped>
.content
	padding-top: 124px
	margin-bottom: 60px
	padding-bottom: 60px
</style>
